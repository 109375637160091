import axios from 'axios'
import { ApolloClient, InMemoryCache } from '@apollo/client/core'

// GraphQL
export const GRAPHQL = new ApolloClient({
  cache: new InMemoryCache(),
  uri: import.meta.env.VITE_GraphQLUrl,
})

// Requests towards new api in OpenShift
export const HTTP = axios.create({
  baseURL: import.meta.env.VITE_ApiUrl,
  headers: {
    'X-Api-Version': import.meta.env.VITE_ApiVersion,
  },
})