import { Region } from '@/models/Book/Region'
import { getRegionsRequest } from '@/api/region.service'
import {
  getBranchesByBranchId,
  getBranchesByPosition,
  getBranchNames,
  getBranchesByRegionId,
  getBranchByIdRequest,
  getBranchesByRegistrationNumber,
  BranchesQueryParams,
} from '@/api/branch.service'
import { defineStore } from 'pinia'
import { useVehicle } from './vehicle.store'
import { GetBranchesResponse } from '@/models/Branch/getBranchesResponse'
import { useBooking } from './booking.store'
import { useRouter } from '@/router'
import { Branch } from '@/models/Branch/Branch'
import { useService } from './service.store'
import { operationTypeEnum } from '@/types/extendedTypes/operationType'
import type { BranchNamesType, Day } from '@/types/generated-types'

interface branchState {
  regions: Region[]
  carReleatedBranches: GetBranchesResponse | null | undefined
  searchResultBranches: GetBranchesResponse | null | undefined
  branchNames: BranchNamesType[]
  showCarRelatedList: Boolean
  personalServiceTechnicianId: string | undefined
}

export const useBranches = defineStore('branches', {
  state: () =>
    ({
      regions: [],
      carReleatedBranches: null as GetBranchesResponse | null | undefined,
      searchResultBranches: null as GetBranchesResponse | null | undefined,
      branchNames: [] as BranchNamesType[],
      showCarRelatedList: true,
      personalServiceTechnicianId: undefined,
    } as branchState),
  actions: {
    async setup() {
      const serviceStore = useService()
      const bookingStore = useBooking()

      if (bookingStore.workshopService.fetchServicePlan) {
        serviceStore.fetchSevicePlanAndConfiguration()
      }

      await this.fetchCarReleatedBranches()
      await this.fetchRegions()
      await this.fetchBranchNames()
    },
    complete(day: Day | undefined, branch: Branch | undefined) {
      const bookingStore = useBooking()

      if (day != undefined) {
        bookingStore.SelectedDate = day
      }

      if (branch != undefined) {
        bookingStore.selectedBranch = branch
      }
    },

    setPersonalServiceTechnicianId(): void {
      this.personalServiceTechnicianId = this.carReleatedBranches?.technician?.id ?? undefined
    },

    goToCalendar() {
      const router = useRouter()
      const bookingStore = useBooking()

      router.push({
        name: 'calendar',
        query: {
          regnr: bookingStore.vehicle.registrationNumber,
          serviceid: bookingStore.options.workshopServiceId,
          branch: bookingStore.selectedBranch?.id,
          ...(bookingStore.typedMileage ? { mileage: bookingStore.typedMileage } : {}),
          ...(bookingStore.selectedContainer?.containerId
            ? { container: bookingStore.selectedContainer.containerId }
            : {}),
        },
      })
    },
    getBranchQueryParams(): BranchesQueryParams {
      const vehicleStore = useVehicle()
      const bookingStore = useBooking()
      return {
        bookingType: operationTypeEnum[bookingStore.workshopService.operationType ?? 0],
        isSlot: bookingStore.workshopService.isSlot,
        regNr: bookingStore.vehicle.registrationNumber ?? '',
        carMake: bookingStore.vehicle.siteCarMake?.value ?? '',
        vehicleCode: bookingStore.vehicle.siteCarMake?.code ?? '',
        isTireHotel: vehicleStore.hasTireHotel(),
        hasTireInCart: bookingStore.hasTireInCart(),
        isTransport: bookingStore.vehicle.isTransportCar,
        serviceId: bookingStore.workshopService.id,
      }
    },

    async fetchRegions(): Promise<void> {
      const vehicleStore = useVehicle()
      const bookingStore = useBooking()
      if (!vehicleStore.hasTireHotelAndIsTireHotelService()) {
        let siteCarMakeValue = bookingStore.vehicle.siteCarMake?.value
        if (
          bookingStore.vehicle.siteCarMake?.value == undefined ||
          bookingStore.vehicle.siteCarMake?.value == ''
        ) {
          siteCarMakeValue = bookingStore.vehicle.make?.toLowerCase() ?? ''
        }

        this.regions = await getRegionsRequest(import.meta.env.VITE_Culture, siteCarMakeValue ?? '')
      }
    },

    async fetchCarReleatedBranches(): Promise<void> {
      const vehicleStore = useVehicle()
      const bookingStore = useBooking()

      if (vehicleStore.hasTireHotelAndIsTireHotelService()) {
        if (bookingStore.workshopService.isSlot) {
          this.carReleatedBranches = await getBranchesByRegistrationNumber(
            bookingStore.workshopService.checkForPersonalServiceTechnician,
            vehicleStore.vehicle.tireHotelInformation?.branchId ?? '',
            this.getBranchQueryParams()
          )
        } else {
          this.carReleatedBranches = await getBranchByIdRequest(
            import.meta.env.VITE_SiteId,
            operationTypeEnum[bookingStore.workshopService.operationType ?? 0],
            bookingStore.vehicle.registrationNumber ?? '',
            bookingStore.vehicle.siteCarMake?.code ?? '',
            bookingStore.vehicle.siteCarMake?.value ?? '',
            import.meta.env.VITE_Culture,

            '' + vehicleStore.vehicle.tireHotelInformation?.branchId,

            !!vehicleStore.vehicle.tireHotelInformation?.branchCode,
            bookingStore.workshopService.id
          )
        }
      } else {
        this.carReleatedBranches = await getBranchesByRegistrationNumber(
          bookingStore.workshopService.checkForPersonalServiceTechnician,
          vehicleStore.vehicle.tireHotelInformation?.branchId ?? '',
          this.getBranchQueryParams()
        )
      }
    },

    async fetchBranchesByPosition(latitude: number, longitude: number): Promise<void> {
      this.showCarRelatedList = false

      this.searchResultBranches = await getBranchesByPosition(
        longitude,
        latitude,
        this.getBranchQueryParams()
      )
    },

    async fetchBranchesById(id: number): Promise<void> {
      this.showCarRelatedList = false

      this.searchResultBranches = await getBranchesByBranchId('' + id, this.getBranchQueryParams())
    },

    async fetchBranchNames(): Promise<void> {
      const bookingStore = useBooking()
      this.branchNames = await getBranchNames(
        bookingStore.workshopService.operationType,
        bookingStore.vehicle.siteCarMake?.code ?? '',
        bookingStore.vehicle.isTransportCar
      )
    },

    async fetchBranchById(branchId: string): Promise<boolean> {
      const bookingStore = useBooking()

      return await getBranchesByBranchId(branchId, this.getBranchQueryParams())
        .then((res) => {
          return res.branches.find((branch) => branch.id == branchId)
        })
        .then((branch) => {
          if (!branch) return false

          bookingStore.selectedBranch = branch
          return true
        })
    },

    async getBranchesByRegionId(regionId: string): Promise<GetBranchesResponse> {
      return await getBranchesByRegionId(regionId, this.getBranchQueryParams())
    },

    async clearSearchResultBranches(): Promise<void> {
      this.showCarRelatedList = true
      this.searchResultBranches = null
    },
    clearBranches() {
      this.searchResultBranches = null
      this.carReleatedBranches = null
      this.regions = [] as Region[]
    },
  },
})
