import { Usp } from '@/types/generated-types'
import { GRAPHQL } from './http.client'
import { useSettings } from '@/store/settings.store'
import userMonitoring from '@/mixins/userMonitoring'
import UspQuery from '@/api/queries/Usp.graphql'
import { ApolloError } from '@apollo/client'

export const getUspsRequest = (carMake: string, serviceId: string): Promise<Usp[]> => {
  const settingStore = useSettings()
  return GRAPHQL.query<any>({
    query: UspQuery,
    variables: {
      siteId: import.meta.env.VITE_SiteId,
      carMake: carMake,
      serviceId: serviceId,
    },
    errorPolicy: 'all',
  })
    .then((response) => {
      if (response.errors) {
        userMonitoring().trackErrorGraphql('UspQuery', JSON.stringify(response.errors))
      }
      return response.data.usp as Usp[]
    })
    .catch((error: ApolloError) => {
      userMonitoring().trackErrorGraphql('UspQuery', error, JSON.stringify({ carMake, serviceId }))
      return [] as Usp[]
    })
}
