import { CarMake as CarMakeObsolete } from '@/models/Vehicle/carMake'
import { HTTP, GRAPHQL } from './http.client'
import userMonitoring, { ErrorTypes } from '@/mixins/userMonitoring'
import CarMakesQuery from '@/api/queries/CarMakes.graphql'
import { ApolloError } from '@apollo/client'
import { CarMake } from '@/types/generated-types'

/**
 * @deprecated This method will be replaced by graphql query CarMakes and can be removed after
 * release 1.54.0
 */
export const getCarMakeNamesRequest = (culture: string): Promise<CarMakeObsolete[]> => {
  return HTTP.get<CarMakeObsolete[]>(`CarMakes/${import.meta.env.VITE_SiteId}`)
    .then((response) => response.data)
    .catch((error) => {
      userMonitoring().trackError(
        `${ErrorTypes.api}: CarMakes/${import.meta.env.VITE_SiteId} failed`,
        error
      )
      return [] as CarMakeObsolete[]
    })
}

export const getCarMakesRequest = (): Promise<CarMake[]> => {
  return GRAPHQL.query<any>({
    query: CarMakesQuery,
    variables: {
      siteId: import.meta.env.VITE_SiteId,
    },
    errorPolicy: 'all',
  })
    .then((response) => {
      if (response.errors) {
        userMonitoring().trackErrorGraphql('CarMakesQuery', JSON.stringify(response.errors))
      }
      return response.data.carMakes as CarMake[]
    })
    .catch((error: ApolloError) => {
      userMonitoring().trackErrorGraphql('CarMakesQuery', error)
      return [] as CarMake[]
    })
}
