interface IDateHelper {
  formatDate: (date: Date) => string
  formatTime: (date: Date) => string
  formatDateShort: (date: Date) => string
  formatToDate: (date: Date) => string
}

export default (): IDateHelper => {
  function firstCharToUpper(value: string): string {
    if (value !== '') {
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
    return value
  }
  function formatDate(date: Date): string {
    return firstCharToUpper(
      date.toLocaleString(import.meta.env.VITE_Culture, {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
      })
    )
  }

  function formatTime(date: Date): string {
    return firstCharToUpper(
      date.toLocaleString(import.meta.env.VITE_Culture, {
        hour: '2-digit',
        minute: '2-digit',
      })
    )
  }

  function formatDateShort(date: Date): string {
    return date.toLocaleString(import.meta.env.VITE_Culture, {
      day: 'numeric',
      month: 'long',
    })
  }

  function formatToDate(date: Date): string {
    return date.toISOString().split('T')[0]
  }

  return {
    formatDate,
    formatTime,
    formatDateShort,
    formatToDate,
  }
}
