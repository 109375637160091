import { defineStore } from 'pinia'
import * as configcat from 'configcat-js'
import { LogLevel } from 'configcat-common'
import short from 'short-uuid'
import userMonitoring from '@/mixins/userMonitoring'

interface ConfigCatState {
  configCatClient: any
  logger: any
  sessionId: string
  featureFlags: object[]
}

export const useConfigCat = defineStore('configCat', {
  state: () =>
    ({
      configCatClient: undefined as any,
      logger: undefined as any,
      sessionId: short.uuid(),
      featureFlags: [],
    } as ConfigCatState),
  actions: {
    init(): void {
      try {
        this.logger = configcat.createConsoleLogger(
          import.meta.env.DEV ? LogLevel.Error : LogLevel.Error
        )
        this.configCatClient = configcat.getClient(
          import.meta.env.VITE_ConfigCat,
          configcat.PollingMode.AutoPoll,
          {
            logger: this.logger,
            dataGovernance: configcat.DataGovernance.EuOnly,
            pollIntervalSeconds: 300,
          }
        )
      } catch (error: any) {
        this.configCatClient = undefined
      }
    },

    async getFeatureFlag(featureFlag: string, defaultValue = false): Promise<boolean> {
      if (this.configCatClient == undefined) {
        return defaultValue
      }
      const userObject = { identifier: this.sessionId }
      return await this.configCatClient
        .getValueAsync(featureFlag, defaultValue, userObject)
        .then((value: boolean) => value)
        .catch((error: any) => {
          userMonitoring().trackError(`ConfigCat getFeatureFlag ${featureFlag} failed`, error)
        })
    },
    async getAllFeatureFlags(): Promise<object> {
      if (this.configCatClient == undefined) {
        return []
      }
      return await this.configCatClient
        .getAllValuesAsync({ identifier: this.sessionId })
        .then((flags: configcat.SettingKeyValue[]) =>
          flags.reduce(
            (acc, { settingKey, settingValue }) => ({ ...acc, [settingKey]: settingValue }),
            {}
          )
        )
        .catch((error: any) => {
          userMonitoring().trackError('ConfigCat getAllFeatureFlags failed', error)
        })
    },

    async getWholeNumberSetting(settingString: string, defaultValue = 0): Promise<number> {
      if (this.configCatClient == undefined) return defaultValue
      const userObject = { identifier: this.sessionId }

      return await this.configCatClient
        .getValueAsync(settingString, defaultValue, userObject)
        .then((value: number) => value)
        .catch((error: any) => {
          userMonitoring().trackError(`ConfigCat getWholeNumberSetting failed`, {
            settingString,
            error,
          })
        })
    },
  },
})
