import { Region } from '@/models/Book/Region'
import { HTTP } from './http.client'
import userMonitoring, { ErrorTypes } from '@/mixins/userMonitoring'

export const getRegionsRequest = (culture: string, carMake: string): Promise<Array<Region>> => {
  return HTTP.get<Array<Region>>(`Region/${import.meta.env.VITE_SiteId}/${carMake}`)
    .then((response) => {
      if (response.status == 204) {
        return []
      }

      return response.data
    })
    .catch((error) => {
      userMonitoring().trackError(
        `${ErrorTypes.api}: Region/${import.meta.env.VITE_SiteId}/${carMake} failed`,
        error
      )
      return []
    })
}
