import { defineStore } from 'pinia'
import { useBooking } from './booking.store'
import { useVehicle } from './vehicle.store'
import { getSchedule, getTimeSlots } from '@/api/branch.service'
import { useRouter } from '@/router'
import { usePreliminaryBooking } from './preliminary-booking.store'
import Container from '@/models/Book/Container'
import objectIsEmpty from '@/mixins/sharedFunctions/objectIsEmpty'
import { useBranches } from './branch.store'
import useDateHelper from '@/mixins/useDateHelper'
import type {
  BookmakerTechnicianType,
  Day,
  GetScheduleResponse,
  Slot,
} from '@/types/generated-types'

interface CalendarState {
  branchSchedule: GetScheduleResponse
  technicians: BookmakerTechnicianType[]
  currentSchedule: Day[]
  oneDaySelected: boolean
  selectedSlot: Slot
  firstAvailableDateMoreSaleWheelShift: Day
}

export const useCalendar = defineStore('calendar', {
  state: () =>
    ({
      branchSchedule: {} as GetScheduleResponse,
      technicians: [] as BookmakerTechnicianType[],
      currentSchedule: [] as Day[],
      oneDaySelected: false as boolean,
      selectedSlot: {} as Slot,
      firstAvailableDateMoreSaleWheelShift: {} as Day,
    } as CalendarState),
  actions: {
    async setup(oneDaySelected: boolean) {
      this.oneDaySelected = oneDaySelected
      await this.fetchBranchSchedule()
    },
    async complete(
      regionId: number,
      containerId: number,
      selectedDate: string,
      container: Container | undefined
    ) {
      const preliminaryBookingStore = usePreliminaryBooking()
      await preliminaryBookingStore.createPreliminaryBooking(
        regionId,
        containerId,
        selectedDate,
        container
      )
    },
    goToCustomerForm() {
      const router = useRouter()
      const bookingStore = useBooking()
      const preliminaryBookingStore = usePreliminaryBooking()

      // If-check to make sure customer has not navigated out of modal before the loading is finished.
      // In future better solution should use currently non-existent endpoint for getting preliminary bookings for validation etc.
      // Check for prelbooking with same date as selected date
      if (
        !objectIsEmpty(bookingStore.selectedBranch) &&
        !objectIsEmpty(bookingStore.SelectedDate) &&
        !!preliminaryBookingStore.isValid
      ) {
        router.push({
          name: 'reservation',
          query: {
            regnr: bookingStore.vehicle.registrationNumber,
            serviceid: bookingStore.workshopService.id,
            branch: bookingStore.selectedBranch?.id,
            ...(bookingStore.typedMileage ? { mileage: bookingStore.typedMileage } : {}),
            ...(bookingStore.selectedContainer?.containerId
              ? { container: bookingStore.selectedContainer?.containerId }
              : {}),
            day: bookingStore.SelectedDate.date,
            time: bookingStore.options.leaveCarTime?.label,
          },
        })
      }
    },
    hasTechnicians(): boolean {
      return this.branchSchedule?.technicians != null && this.branchSchedule.technicians.length > 0
    },

    async fetchBranchSchedule(): Promise<void> {
      const bookingStore = useBooking()
      if (bookingStore.selectedBranch != undefined) {
        const vehicleStore = useVehicle()
        if (this.showDateWarningMoreSaleTireHotel()) {
          await this.fetchScheduleForTireHotelServiceMoreSale(true)
        } else {
          if (!bookingStore.workshopService.isSlot) {
            this.branchSchedule = await getSchedule(
              bookingStore.selectedBranch?.id ?? '',
              bookingStore.vehicle.siteCarMake?.code ?? '',
              bookingStore.hasTireInCart(),
              vehicleStore.hasTireHotel(),
              0,
              this.oneDaySelected,
              bookingStore.workshopService.id,
              vehicleStore.vehicle.make ?? '',
              bookingStore.getFirstBookableDate(this.oneDaySelected),
              false,
              vehicleStore.vehicle.registrationNumber ?? '')

            this.currentSchedule = this.branchSchedule.days?.map((day) => ({ ...day })) ?? []
            this.technicians = this.branchSchedule.technicians ?? []

            const BranchStore = useBranches()
            if (BranchStore.personalServiceTechnicianId !== undefined) {
              const SelectedPersonalServiceTechnician = this.technicians?.find(
                (technician: { id: string }) =>
                  technician.id === BranchStore.personalServiceTechnicianId
              )
              if (SelectedPersonalServiceTechnician != undefined) {
                bookingStore.selectedContainer = {
                  containerId: SelectedPersonalServiceTechnician.containerId ?? -1,
                  technician: SelectedPersonalServiceTechnician,
                }
              }
            }
          } else {
            this.branchSchedule = await getSchedule(
              bookingStore.selectedBranch?.id ?? '',
              bookingStore.vehicle.siteCarMake?.code ?? '',
              bookingStore.hasTireInCart(),
              vehicleStore.hasTireHotel(),
              0,
              this.oneDaySelected,
              bookingStore.workshopService.id,
              vehicleStore.vehicle.make ?? '',
              bookingStore.getFirstBookableDate(this.oneDaySelected),
              false,
              vehicleStore.vehicle.registrationNumber ?? '')
            this.currentSchedule = this.branchSchedule.days?.map((day) => ({ ...day })) ?? []
            this.technicians = this.branchSchedule.technicians ?? []
          }
        }
      }
    },

    async fetchTimeSlots(selectedDate: string): Promise<any> {
      const bookingStore = useBooking()
      const vehicleStore = useVehicle()
      return await getTimeSlots(
        bookingStore.selectedBranch?.id ?? '',
        selectedDate,
        bookingStore.workshopService.id,
        vehicleStore.vehicle.make ?? ''
      )
    },

    // @deprecated this method is not being used
    async fetchSlotBranchSchedule(): Promise<void> {
      const bookingStore = useBooking()
      if (bookingStore.selectedBranch != undefined) {
        const vehicleStore = useVehicle()
        const bookingStore = useBooking()
        const dateHelper = useDateHelper()

        this.branchSchedule = await getSchedule(
          bookingStore.selectedBranch?.id ?? '',
          bookingStore.vehicle.siteCarMake?.code ?? '',
          bookingStore.hasTireInCart(),
          vehicleStore.hasTireHotel(),
          0,
          this.oneDaySelected,
          bookingStore.workshopService.id,
          vehicleStore.vehicle.make ?? '',
          dateHelper.formatDate(new Date()),
          false,
          vehicleStore.vehicle.registrationNumber ?? ''
        )

        this.currentSchedule = this.branchSchedule.days?.map((day) => ({ ...day })) ?? []
        this.technicians = this.branchSchedule.technicians ?? []
      }
    },

    async fetchScheduleForTireHotelServiceMoreSale(saveCalendar: boolean): Promise<void> {
      const bookingStore = useBooking()

      if (bookingStore.selectedBranch != undefined) {
        const schedule = await getSchedule(
          bookingStore.selectedBranch?.id ?? '',
          bookingStore.vehicle.siteCarMake?.code ?? '',
          bookingStore.hasTireInCart(),
          true,
          0,
          this.oneDaySelected,
          bookingStore.workshopService.id,
          bookingStore.vehicle.make ?? '',
          bookingStore.getFirstBookableDate(this.oneDaySelected),
          true, // Used to fetch calendar for wheel shift
          bookingStore.vehicle.registrationNumber ?? ''
        )

        const firstDate = schedule.days?.find((day) => day.available) ?? ({} as Day)

        if (firstDate > bookingStore.SelectedDate.date) {
          this.firstAvailableDateMoreSaleWheelShift = firstDate as Day
        }

        if (saveCalendar) {
          this.branchSchedule = schedule
          this.currentSchedule = this.branchSchedule.days?.map((day) => ({ ...day })) ?? []
          this.technicians = this.branchSchedule.technicians ?? []
        }
      }
    },

    showDateWarningMoreSaleTireHotel(): boolean {
      const bookingStore = useBooking()
      if (objectIsEmpty(this.firstAvailableDateMoreSaleWheelShift)) {
        return false
      }
      return this.firstAvailableDateMoreSaleWheelShift.date > bookingStore.SelectedDate.date
    },

    navigateToCalendarWheelShiftMoreSaleSelected() {
      const router = useRouter()
      const bookingStore = useBooking()
      const preliminaryBookingStore = usePreliminaryBooking()
      preliminaryBookingStore.clearPreliminaryBooking()

      router.push({
        name: 'calendar',
        query: {
          regnr: bookingStore.vehicle.registrationNumber,
          serviceid: bookingStore.options.workshopServiceId,
          branch: bookingStore.selectedBranch?.id,
          ...(bookingStore.typedMileage ? { mileage: bookingStore.typedMileage } : {}),
          ...(bookingStore.selectedContainer?.containerId
            ? { container: bookingStore.selectedContainer.containerId }
            : {}),
        },
      })
    },
  },
})
