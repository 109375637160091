import { SystemTextKeys } from '@/models/Enums/SystemTextKeys'
import { HTTP, GRAPHQL } from './http.client'
import userMonitoring, { ErrorTypes } from '@/mixins/userMonitoring'
import resourcesByGroups from '@/api/queries/TextQuery.graphql'
import { BookConfirmation } from '@/models/SystemTexts/BookConfirmation'
import { CarMake } from '@/models/Vehicle/carMake'
import { Customer } from '@/models/SystemTexts/Customer'
import { GeneralTexts } from '@/models/SystemTexts/GeneralTexts'
import { CalendarTexts } from '@/models/SystemTexts/CalendarTexts'
import { VehicleStep } from '@/models/SystemTexts/VehicleStep'
import { ApolloError } from '@apollo/client'
import { RimRenovation } from '@/models/SystemTexts/RimRenovations'

export const getSystemTextsRequest = <T>(
  culture: string,
  systemTextKey: SystemTextKeys
): Promise<T> => {
  return HTTP.get<T>(`Resource/${import.meta.env.VITE_SiteId}/${systemTextKey}`)
    .then((res) => res.data)
    .catch((error) => {
      userMonitoring().trackError(
        `${ErrorTypes.api}: Resource/${
          import.meta.env.VITE_SiteId
        }/${systemTextKey} failed with error`,
        { culture, error }
      )
      return {} as T
    })
}

export const graphQlGetSystemTextsRequest = <T>(
  groups: SystemTextKeys[]
): Promise<{
  bookConfirmationText: BookConfirmation
  carMake: CarMake
  customer: Customer
  general: GeneralTexts
  timeAndPlace: CalendarTexts
  vehicle: VehicleStep
  rimRenovation: RimRenovation
}> => {
  return GRAPHQL.query<{
    resourcesByGroups: {
      bookConfirmationText: BookConfirmation
      carMake: CarMake
      customer: Customer
      general: GeneralTexts
      timeAndPlace: CalendarTexts
      vehicle: VehicleStep
      rimRenovation: RimRenovation
    }
  }>({
    query: resourcesByGroups,
    variables: { siteId: import.meta.env.VITE_SiteId, groups: groups },
    errorPolicy: 'all',
  })
    .then((response) => {
      if (response.errors) {
        userMonitoring().trackErrorGraphql('ResourcesByGroups', JSON.stringify(response.errors))
      }
      return response.data.resourcesByGroups
    })
    .catch((error: ApolloError) => {
      userMonitoring().trackErrorGraphql(
        'ResourcesByGroups',
        error,
        JSON.stringify({ siteId: import.meta.env.VITE_SiteId, groups: groups })
      )
      return {} as any
    })
}
