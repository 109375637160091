import { Settings } from '@/models/Application/settings'
import { GRAPHQL } from './http.client'
import userMonitoring, { ErrorTypes } from '@/mixins/userMonitoring'
import BookingSettingsQuery from '@/api/queries/BookingSettings.graphql'
import { ApolloError } from '@apollo/client'

export const getSettingsRequest = (culture: string): Promise<Settings> => {
  return GRAPHQL.query<any>({
    query: BookingSettingsQuery,
    variables: {
      siteId: import.meta.env.VITE_SiteId,
    },
    errorPolicy: 'all',
  })
    .then((response) => {
      if (response.errors) {
        userMonitoring().trackErrorGraphql('BookingSettingsQuery', JSON.stringify(response.errors))
      }
      return response.data.bookingSettings as Settings
    })
    .catch((error: ApolloError) => {
      userMonitoring().trackErrorGraphql(
        'BookingSettingsQuery',
        error,
        JSON.stringify({ siteId: import.meta.env.VITE_SiteId })
      )
      return {} as Settings
    })
}
