import { getSystemTextsRequest, graphQlGetSystemTextsRequest } from '@/api/system-text.service'
import { getUspsRequest } from '@/api/usp.service'
import { Usp } from '@/types/generated-types'
import { SystemTextKeys } from '@/models/Enums/SystemTextKeys'
import { BookConfirmation } from '@/models/SystemTexts/BookConfirmation'
import { CalendarTexts } from '@/models/SystemTexts/CalendarTexts'
import { Customer } from '@/models/SystemTexts/Customer'
import { GeneralTexts } from '@/models/SystemTexts/GeneralTexts'
import { VehicleStep } from '@/models/SystemTexts/VehicleStep'
import { defineStore } from 'pinia'
import { useVehicle } from './vehicle.store'
import { useSettings } from './settings.store'
import { useBooking } from './booking.store'
import { RimRenovation } from '@/models/SystemTexts/RimRenovations'

interface TextState {
  generalText: GeneralTexts
  vehicleText: VehicleStep
  timeAndPlaceText: CalendarTexts
  customerText: Customer
  confirmationText: BookConfirmation
  rimRenovationText: RimRenovation
  debugText: boolean
  usps: Usp[]
}

export const useText = defineStore('text', {
  state: () =>
    ({
      generalText: {} as GeneralTexts,
      vehicleText: {} as VehicleStep,
      timeAndPlaceText: {} as CalendarTexts,
      customerText: {} as Customer,
      confirmationText: {} as BookConfirmation,
      rimRenovationText: {} as RimRenovation,
      debugText: false,
      usps: {} as Usp[],
    } as TextState),
  actions: {
    async fetchGeneralText(): Promise<void> {
      const settingsStore = useSettings()
      this.generalText = await getSystemTextsRequest(
        import.meta.env.VITE_Culture,
        SystemTextKeys.general
      )
    },

    async fetchVehicleText(): Promise<void> {
      const settingsStore = useSettings()
      this.vehicleText = await getSystemTextsRequest(
        import.meta.env.VITE_Culture,
        SystemTextKeys.vehicle
      )
    },

    async fetchTimeAndPlace(): Promise<void> {
      const settingsStore = useSettings()
      this.timeAndPlaceText = await getSystemTextsRequest(
        import.meta.env.VITE_Culture,
        SystemTextKeys.timeAndPlace
      )
    },

    async fetchCustomerText(): Promise<void> {
      const settingsStore = useSettings()
      this.customerText = await getSystemTextsRequest(
        import.meta.env.VITE_Culture,
        SystemTextKeys.customer
      )
    },

    async fetchConfirmationText(): Promise<void> {
      const settingsStore = useSettings()
      this.confirmationText = await getSystemTextsRequest(
        import.meta.env.VITE_Culture,
        SystemTextKeys.bookConfirmationText
      )
    },

    async fetchRimRenovationTexts(): Promise<void> {
      const settingsStore = useSettings()
      this.rimRenovationText = await getSystemTextsRequest(
        import.meta.env.VITE_Culture,
        SystemTextKeys.rimRenovation
      )
    },

    async fetchAllTexts(): Promise<void> {
      graphQlGetSystemTextsRequest([
        SystemTextKeys.vehicle,
        SystemTextKeys.timeAndPlace,
        SystemTextKeys.customer,
        SystemTextKeys.bookConfirmationText,
        SystemTextKeys.general,
        SystemTextKeys.rimRenovation,
      ]).then((res) => {
        this.generalText = res[SystemTextKeys.general]
        this.vehicleText = res[SystemTextKeys.vehicle]
        this.timeAndPlaceText = res[SystemTextKeys.timeAndPlace]
        this.customerText = res[SystemTextKeys.customer]
        this.confirmationText = res[SystemTextKeys.bookConfirmationText]
        this.rimRenovationText = res[SystemTextKeys.rimRenovation]
      })
    },

    async fetchUsp(): Promise<void> {
      const vehicleStore = useVehicle()
      const bookingStore = useBooking()

      this.usps = await getUspsRequest(
        vehicleStore.vehicle.siteCarMake?.value ?? '',
        bookingStore.workshopService.id
      )
    },
  },
})
