<template>
  <div class="chip-choice">
    <input
      :id="id"
      :name="name"
      :value="value"
      :checked="modelValue === value"
      :disabled="disabled"
      :required="required"
      type="radio"
      @change="onChange"
      @blur="onBlur"
      @invalid="onInvalid"
    />
    <label :for="id" :aria-label="label ? label : undefined" class="chip-choice-label">
      <slot />
    </label>
  </div>
</template>

<script lang="ts" setup>
defineProps({
  id: {
    type: String,
    required: true,
  },
  value: {
    type: [String, Number],
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: [String, Number],
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue'])

function onChange(e: Event) {
  emit('update:modelValue', (e.target as HTMLInputElement).value)
}

function onBlur(e: Event) {
  const el: HTMLInputElement = e.target as HTMLInputElement
  el.reportValidity()
}

function onInvalid(e: Event) {
  addErrorStyling(e)
}

function addErrorStyling(e: Event) {
  const el: HTMLInputElement = e.target as HTMLInputElement
  el.classList.add('error')
}
</script>

<style lang="scss" scoped>
@import './src/assets/scss/base';

.chip-choice {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/ 1;
  border: 1px solid var(--color-bilia-sand-100);
  position: relative;

  .chip-choice-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: center;
  }

  & label {
    width: 100%;
    height: 100%;
  }

  & > input {
    appearance: none;
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: var(--size-full);
    background-color: var(--color-bilia-white);
    transition-property: border-color, color, background-color;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
    cursor: pointer;
    border-radius: 0;

    &:checked {
      border: 1px solid var(--color-bilia-black);
      background-color: var(--color-bilia-blue-selected);

      &:before {
        opacity: 1;
      }
    }

    &:hover:not(:disabled):not(:checked) {
      background-color: var(--color-brand-grey-20p);
    }

    &:focus {
      outline-offset: -2px;
      outline: 1px solid var(--color-bilia-primary-blue);
    }

    &:disabled {
      border-color: var(--color-brand-grey-20p);
      cursor: not-allowed;

      &:before {
        border-color: var(--color-brand-grey-20p);
        background-color: var(--color-brand-grey-20p);
      }

      & ~ label {
        color: var(--color-brand-grey-20p);
        cursor: not-allowed;

        & > * {
          color: var(--color-brand-grey-20p);
        }
      }
    }

    &:invalid:not(:disabled).error {
      border-color: var(--color-bilia-active-red);
      background-color: var(--bilia-light-red);
    }
  }

  & > label {
    position: absolute;
    @include hellix-semibold;
    font-size: var(--font-n);
    transition-property: color;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
    cursor: pointer;
  }
}
</style>
