import { GRAPHQL } from './http.client'
import userMonitoring, { ErrorTypes } from '@/mixins/userMonitoring'
import ServiceGroupsQuery from '@/api/queries/ServiceGroups.graphql'
import ServiceQuery from '@/api/queries/Service.graphql'
import {
  MotoritOnlineQueriesServiceArgs,
  MotoritOnlineQueriesServiceGroupsArgs,
  WorkshopService,
  ServiceGroupResponse,
} from '@/types/generated-types'
import { useVehicle } from '@/store/vehicle.store'

export const getServiceGroupsRequest = (): Promise<ServiceGroupResponse> => {
  const vehicleStore = useVehicle()
  const payload = {
    languageCode: import.meta.env.VITE_Culture,
    siteId: import.meta.env.VITE_SiteId,
    carMake: vehicleStore.vehicle.siteCarMake?.value,
    model: vehicleStore.vehicle.model,
    year: vehicleStore.vehicle.year,
    classificationCode: vehicleStore.vehicle.classificationCode,
    hasTireHotel: vehicleStore.hasTireHotel(),
    typeHigh: vehicleStore.vehicle.typeHigh,
    registrationNumber: vehicleStore.vehicle.registrationNumber,
  } as MotoritOnlineQueriesServiceGroupsArgs
  return GRAPHQL.query<any>({
    query: ServiceGroupsQuery,
    variables: payload,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  })
    .then((response) => {
      if (response.errors) {
        console.log(`${ErrorTypes.graphQL}: serviceGroups failed with error`, {
          payload,
          errors: response.errors,
        })
        userMonitoring().trackError(`${ErrorTypes.graphQL}: serviceGroups failed with error`, {
          payload,
          errors: response.errors,
        })
      }
      return response.data.serviceGroups as ServiceGroupResponse
    })
    .catch((error) => {
      userMonitoring().trackError(`${ErrorTypes.graphQL}: serviceGroups failed with error`, {
        payload,
        error,
      })
      return {} as ServiceGroupResponse
    })
}

export const getServiceRequest = (serviceIdList: string[]): Promise<WorkshopService> => {
  const vehicleStore = useVehicle()
  const payload = {
    siteId: import.meta.env.VITE_SiteId,
    carMake: vehicleStore.vehicle.siteCarMake?.value,
    model: vehicleStore.vehicle.model,
    year: vehicleStore.vehicle.year,
    classificationCode: vehicleStore.vehicle.classificationCode,
    hasTireHotel: vehicleStore.hasTireHotel(),
    serviceIdList: serviceIdList,
    registrationNumber: vehicleStore.vehicle.registrationNumber
  } as MotoritOnlineQueriesServiceArgs
  return GRAPHQL.query<any>({
    query: ServiceQuery,
    variables: payload,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  })
    .then((response) => {
      if (response.errors) {
        userMonitoring().trackError(`${ErrorTypes.graphQL}: service failed with error`, {
          payload,
          errors: response.errors,
        })
      }
      return response.data.service as WorkshopService
    })
    .catch((error) => {
      console.log(`${ErrorTypes.graphQL}: service failed with error: ${error}`)
      userMonitoring().trackError(`${ErrorTypes.graphQL}: service failed with error`, {
        payload,
        error,
      })
      return {} as WorkshopService
    })
}
